import { CommonModule } from '@angular/common';
import { NgModule, type ModuleWithProviders } from '@angular/core';
import { NgxsModule } from '@ngxs/store';

import { NgxsAfterSuccessModule } from '@cosmos/state';
import { AlgoliaTasksInterceptor } from '@esp/search/data-access-algolia-tasks';

import { NotesSearchActions } from './actions';
import { NotesService } from './services';
import { NotesSearchState } from './states';

@NgModule()
export class EspNotesModule {
  static forRoot(): ModuleWithProviders<RootEspNotesModule> {
    return { ngModule: RootEspNotesModule };
  }
}

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([NotesSearchState]),
    NgxsAfterSuccessModule,
  ],
  providers: [
    NotesService,
    AlgoliaTasksInterceptor.create({
      urlPattern: /telephus\/supplier/,
      ActionToDispatch: NotesSearchActions.SearchIndexOperationComplete,
    }),
  ],
})
export class RootEspNotesModule {}
