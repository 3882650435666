import { Injectable } from '@angular/core';
import { Action, State, type StateContext } from '@ngxs/store';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { syncLoadProgress } from '@cosmos/state';
import type { ModelWithLoadingStatus } from '@cosmos/types-common';
import { ToastActions } from '@cosmos/types-notification-and-toast';
import { handleNgxsError } from '@cosmos/util-common';
import { SearchCriteria, type SearchResult } from '@esp/common/types';
import { CompaniesService } from '@esp/companies/data-access-api';
import type {
  CompanyDecoration,
  CompanyDecorationSearch,
} from '@esp/orders/types';

import { CompaniesDecorationsActions } from '../actions';

import { TOAST_MESSAGES } from './toast-messages';

export interface CompaniesDecorationsStateModel
  extends SearchResult<CompanyDecorationSearch>,
    ModelWithLoadingStatus {
  criteria: SearchCriteria;
  currentDecoration: CompanyDecoration | null;
}

type ThisStateModel = CompaniesDecorationsStateModel;
type ThisStateContext = StateContext<ThisStateModel>;

@State<CompaniesDecorationsStateModel>({
  name: 'companiesDecorations',
  defaults: {
    criteria: new SearchCriteria(),
    Results: undefined,
    ResultsTotal: 0,
    Aggregations: null,
    currentDecoration: null,
  },
})
@Injectable()
export class CompaniesDecorationsState {
  constructor(private readonly _service: CompaniesService) {}

  @Action(CompaniesDecorationsActions.Activate)
  private _activate(
    ctx: ThisStateContext,
    { decoration }: CompaniesDecorationsActions.Activate
  ) {
    return this._service.activateDecoration(decoration).pipe(
      tap(() => {
        ctx.dispatch(
          new ToastActions.Show(TOAST_MESSAGES.DECORATION_ACTIVATED(decoration))
        );
      }),
      catchError(() => {
        ctx.dispatch(
          new ToastActions.Show(
            TOAST_MESSAGES.DECORATION_NOT_ACTIVATED(decoration)
          )
        );

        return EMPTY;
      })
    );
  }

  @Action(CompaniesDecorationsActions.Archive)
  private _archive(
    ctx: ThisStateContext,
    { decoration }: CompaniesDecorationsActions.Archive
  ) {
    return this._service.archiveDecoration(decoration).pipe(
      tap(() => {
        ctx.dispatch(
          new ToastActions.Show(TOAST_MESSAGES.DECORATION_ARCHIVED(decoration))
        );
      }),
      catchError(() => {
        ctx.dispatch(
          new ToastActions.Show(
            TOAST_MESSAGES.DECORATION_NOT_ARCHIVED(decoration)
          )
        );

        return EMPTY;
      })
    );
  }

  @Action(CompaniesDecorationsActions.Delete)
  private _delete(
    ctx: ThisStateContext,
    { decoration }: CompaniesDecorationsActions.Delete
  ) {
    return this._service.deleteDecoration(decoration).pipe(
      tap(() => {
        ctx.dispatch(
          new ToastActions.Show(TOAST_MESSAGES.DECORATION_DELETED(decoration))
        );
      }),
      catchError(() => {
        ctx.dispatch(
          new ToastActions.Show(
            TOAST_MESSAGES.DECORATION_NOT_DELETED(decoration)
          )
        );

        return EMPTY;
      })
    );
  }

  @Action(CompaniesDecorationsActions.Get)
  private _getDecoration(
    ctx: ThisStateContext,
    { companyId, decorationId }: CompaniesDecorationsActions.Get
  ) {
    return this._service.getDecoration(companyId, decorationId).pipe(
      tap((decoration) => {
        ctx.patchState({
          currentDecoration: decoration,
        });
      })
    );
  }

  @Action(CompaniesDecorationsActions.PrepareCriteria)
  private _prepareCriteria(
    ctx: ThisStateContext,
    { criteria }: CompaniesDecorationsActions.PrepareCriteria
  ) {
    ctx.patchState({
      criteria,
    });
  }

  @Action(CompaniesDecorationsActions.ResetCurrentDecoration)
  private _resetCurrentDecoration(ctx: ThisStateContext) {
    ctx.patchState({
      currentDecoration: null,
    });
  }

  @Action(CompaniesDecorationsActions.SearchWithExistingCriteria)
  private _searchWithExistingCriteria(ctx: ThisStateContext) {
    ctx.dispatch(
      new CompaniesDecorationsActions.Search(
        ctx.getState().criteria as SearchCriteria
      )
    );
  }

  @Action(CompaniesDecorationsActions.Search)
  private _search(
    ctx: ThisStateContext,
    { criteria }: CompaniesDecorationsActions.Search
  ) {
    ctx.patchState({
      criteria: {
        ...ctx.getState().criteria,
        ...criteria,
      },
    });

    return this._service
      .searchDecorations(ctx.getState().criteria?.id as number, criteria)
      .pipe(
        syncLoadProgress(ctx),
        tap((res) => {
          ctx.patchState({
            ...res,
          });
        })
      );
  }

  @Action(CompaniesDecorationsActions.Create)
  private _create(
    ctx: ThisStateContext,
    { decoration }: CompaniesDecorationsActions.Create
  ) {
    return this._service.createDecoration(decoration).pipe(
      tap(() =>
        ctx.dispatch(
          new ToastActions.Show(TOAST_MESSAGES.DECORATION_CREATED(decoration))
        )
      ),
      handleNgxsError((error) => {
        // The `detail` may have an error text such as
        // `Company already has a file named '1964252.png'`.
        let errorMessage = '';
        if (error.error?.detail) {
          errorMessage = error.error.detail;
        } else if (typeof error.error === 'string') {
          errorMessage = error.error;
        }

        ctx.dispatch(
          new ToastActions.Show(
            TOAST_MESSAGES.DECORATION_NOT_CREATED(decoration, errorMessage)
          )
        );
      })
    );
  }

  @Action(CompaniesDecorationsActions.Patch)
  private _patch(
    ctx: ThisStateContext,
    { decoration }: CompaniesDecorationsActions.Patch
  ) {
    return this._service.editDecoration(decoration).pipe(
      tap(() => {
        ctx.dispatch(
          new ToastActions.Show(TOAST_MESSAGES.DECORATION_UPDATED(decoration))
        );
      }),
      handleNgxsError((error) => {
        // The `detail` may have an error text such as
        // `Company already has a file named '1964252.png'`.
        let errorMessage = '';
        if (error.error?.detail) {
          errorMessage = error.error.detail;
        } else if (typeof error.error === 'string') {
          errorMessage = error.error;
        }

        ctx.dispatch(
          new ToastActions.Show(
            TOAST_MESSAGES.DECORATION_NOT_UPDATED(decoration, errorMessage)
          )
        );
      })
    );
  }
}
